<svelte:head>
  <link
    rel="preload"
    href="/fonts/libre-baskerville.woff2"
    crossorigin="anonymous"
    as="font"
    type="font/woff2"
  />
</svelte:head>

<slot />
